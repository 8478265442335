const longClick = function(element, timeout) {
  let timer, longtouch;

  const handlerClick = () => {
    if (longtouch) {
      longtouch = false;
      return false;
    } else {
      const event = new Event('tap');
      element.dispatchEvent(event);
    };
  };

  const handlerStart = () => {
    timer = setTimeout(function () {
      longtouch = true;
      const event = new Event('press');
      element.dispatchEvent(event);
    }, timeout || 500);
  };

  const handlerEnd = () => {
    clearTimeout(timer);
  };

  element.addEventListener('click', handlerClick);

  ['touchstart', 'mousedown'].forEach(eventName => {
    element.addEventListener(eventName, handlerStart, { passive: true });
  });

  ['mouseup', 'touchend', 'touchmove'].forEach(eventName => {
    element.addEventListener(eventName, handlerEnd, { passive: true });
  });
};
