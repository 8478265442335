define("emberfire/mixins/analytics-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    firebaseApp: Ember.inject.service('firebase-app'),
    router: Ember.inject.service('router'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var router = Ember.get(this, 'router');
      router.on('routeDidChange', function () {
        var firebase = Ember.get(_this, 'firebaseApp');
        var app_name = config.APP.name || 'Ember App';
        var app_version = config.APP.version || undefined;
        var screen_name = router.currentRouteName;
        var url = router.currentURL;
        firebase.analytics().then(function (analytics) {
          if (analytics) {
            analytics.setCurrentScreen(screen_name || url, {
              global: true
            });
            analytics.logEvent("screen_view", {
              app_name: app_name,
              screen_name: screen_name,
              url: url,
              app_version: app_version
            });
          }
        });
      });
    }
  });

  _exports.default = _default;
});